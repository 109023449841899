var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('b-card',[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-forgot-password-form mt-2"},[_c('b-form-group',{attrs:{"label":"Office Name","label-for":"office-name"}},[_c('validation-provider',{attrs:{"name":"office-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-name","state":errors.length > 0 ? false:null,"name":"office-name"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Shortname","label-for":"office-shortname"}},[_c('validation-provider',{attrs:{"name":"office-shortname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-shortname","state":errors.length > 0 ? false:null,"name":"office-shortname"},model:{value:(_vm.data.shortName),callback:function ($$v) {_vm.$set(_vm.data, "shortName", $$v)},expression:"data.shortName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone","placeholder":"604.000.0000"},on:{"input":function($event){return _vm.formatPhoneNumber(_vm.data.phone, 'phone')}},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Fax","label-for":"office-fax"}},[_c('validation-provider',{attrs:{"name":"office-fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-fax","state":errors.length > 0 ? false:null,"name":"office-fax"},model:{value:(_vm.data.fax),callback:function ($$v) {_vm.$set(_vm.data, "fax", $$v)},expression:"data.fax"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Website","label-for":"office-website"}},[_c('validation-provider',{attrs:{"name":"office-website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-website","state":errors.length > 0 ? false:null,"name":"office-website"},model:{value:(_vm.data.website),callback:function ($$v) {_vm.$set(_vm.data, "website", $$v)},expression:"data.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-primary","type":"submit","disabled":invalid},on:{"click":function($event){return _vm.onOfficeSaveClick()}}},[_vm._v(" Submit ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }