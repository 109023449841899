<template>
  <div class="page">
    <b-card>
      <validation-observer
        ref="simpleRules"
        #default="{invalid}"
      >
        <b-form
          class="auth-forgot-password-form mt-2"
        >
          <b-form-group
            label="Office Name"
            label-for="office-name"
          >
            <validation-provider
              #default="{ errors }"
              name="office-name"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="office-name"
                  v-model="data.name"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="office-name"
                />
              </b-input-group><small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Shortname"
            label-for="office-shortname"
          >
            <validation-provider
              #default="{ errors }"
              name="office-shortname"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="office-shortname"
                  v-model="data.shortName"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="office-shortname"
                />
              </b-input-group><small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="phone"
                  v-model="data.phone"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="phone"
                  placeholder="604.000.0000"
                  @input="formatPhoneNumber(data.phone, 'phone')"
                />
              </b-input-group><small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Fax"
            label-for="office-fax"
          >
            <validation-provider
              #default="{ errors }"
              name="office-fax"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="office-fax"
                  v-model="data.fax"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="office-fax"
                />
              </b-input-group><small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Website"
            label-for="office-website"
          >
            <validation-provider
              #default="{ errors }"
              name="office-website"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="office-website"
                  v-model="data.website"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="office-website"
                />
              </b-input-group><small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            class="mt-1"
            variant="outline-primary"
            type="submit"
            :disabled="invalid"
            @click="onOfficeSaveClick()"
          >
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BButton, BFormGroup, BForm, BInputGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, ValidationProvider, ValidationObserver, BFormGroup, BForm, BInputGroup, BFormInput,
  },
  data() {
    return {
      required,
      data: {
        name: '',
        shortName: '',
        phone: '',
        fax: '',
        website: '',
      },
    }
  },
  methods: {
    formatPhoneNumber(data, phoneNumberString) {
      const cleaned = ('' + data).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        if (phoneNumberString === 'phone') {
          this.data.phone = match[1] + '.' + match[2] + '.' + match[3]
        } else if (phoneNumberString === 'office-phone') {
          this.data.officeNumber = match[1] + '.' + match[2] + '.' + match[3]
        } else if (phoneNumberString === 'office-fax') {
          this.data.officeFax = match[1] + '.' + match[2] + '.' + match[3]
        }
      }
    },
    onOfficeSaveClick() {
      this.$store.dispatch('superadmin/createOffice', this.data).then(response => {
        console.log(response)
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>

<style>

</style>
